import PropTypes from "prop-types";
import { connect } from "react-redux";

import { capitalizeFirstLetter } from "../../../lib/formattingFunctions";
import { PRODUCT_NAME_CREDIT, PRODUCT_NAMES } from "../../products/lib/ProductVars";

import FormatNumber from "../../layout/FormatNumber";
import FormatDate from "../../layout/FormatDate";

import { translate } from "../../../translations/translations";

const Invoice = ({ auth: { user }, data }) => {
  const PRODUCTS_WITHOUT_PERIODS = [PRODUCT_NAME_CREDIT, PRODUCT_NAMES.domainTransfer];

  return data.invoiceItemsToRender.length > 0 ? (
    <div className="bg-light rounded-3 p-3 mt-3">
      {/* Logo */}
      <img src="/assets/img/satonda_gradient.png" alt="Satonda" style={{ width: "140px" }} />
      {/* From & invoice details */}
      <div className="row mt-5">
        <div className="col-6">
          <p className="mb-1 text-bold">{data.from.from}</p>
          <p className="mb-1">{data.from.satondaName}</p>
          {/* <p className="mb-1">{data.from.satondaStreet}</p>
          <p className="mb-1">{data.from.satondaPostalCityCountry}</p> */}
          <p className="mb-1">{data.from.satondaVatNumber}</p>
        </div>
        <div className="col-6">
          <p className="mb-1 text-bold">{data.invoiceDetails.invoiceDetails}</p>
          <p className="mb-1">{data.invoiceDetails.invoiceRef}</p>
          <p className="mb-1">{data.invoiceDetails.invoiceDate}</p>
          {/* <p className="mb-1">{data.invoiceDetails.dueDate}</p> */}
          <p className="mb-1">{data.invoiceDetails.paymentStatus}</p>
        </div>
      </div>
      {/* Billing details */}
      <div className="mt-5">
        <p className="mb-1 text-bold">{data.billingDetails.billingDetails}</p>
        <p className="mb-1">
          {data.billingDetails.wsName} <span className="text-italic">(ID: {data.billingDetails.wsId})</span>
        </p>
        <p className="mb-1">{data.billingDetails.customerName}</p>
        <p className="mb-1">{data.billingDetails.email}</p>
        <p className="mb-1">{data.billingDetails.street}</p>
        <p className="mb-1">
          {data.billingDetails.postal}, {data.billingDetails.city}
        </p>
        <p className="mb-1">{data.billingDetails.country}</p>
      </div>
      {/* Invoice table */}
      <div className="rounded-3 overflowHidden border border-primary mt-5">
        <div className="row mx-0 bg-primary text-white text-bold">
          <div className="col-5 p-2">{translate("cWsBilling.item", false, null)}</div>
          <div className="col-4 p-2">{translate("cWsBilling.period", false, null)}</div>
          <div className="col-3 p-2">{translate("cWsBilling.invoiceAmount", false, null)}</div>
        </div>
        {data.invoiceItemsToRender.map((invoice, i) => (
          <div className="row mx-0" key={`invoiceItemsToRender_${i}`}>
            <div className="col-5 p-2">
              {translate(`cWsBilling.product_${invoice.productName.replace(/\s/g, "").toLowerCase()}`, false, null)} -{" "}
              {capitalizeFirstLetter(invoice.packageName)}
            </div>
            <div className="col-4 p-2">
              {!PRODUCTS_WITHOUT_PERIODS.includes(invoice.productName) && (
                <>
                  <FormatDate dateString={invoice.fromDate} lang={data.language} formatStr={"dmmyy"} /> -{" "}
                  <FormatDate dateString={invoice.toDate} lang={data.language} formatStr={"dmmyy"} />
                </>
              )}
            </div>
            <div className="col-3 p-2">
              {data.invoiceCurrency} <FormatNumber number={invoice.invoiceAmount} formatStr={"0.00"} />
            </div>
          </div>
        ))}
        <div className="row mx-0 bg-primary opacity-50 text-light text-bold">
          {/* If US, set tax to "sales tax" */}
          <div className="col-9 p-2 d-flex align-items-center">{translate("cWsBilling.totalExcVat", false, { tax: "VAT" })}</div>
          <div className="col-3 p-2 d-flex align-items-center text-left">
            {data.invoiceCurrency} <FormatNumber number={data.invoiceAmountExcVat} formatStr={"0.00"} />
          </div>
        </div>
        <div className="row mx-0 text-dark">
          <div className="col-9 p-2 d-flex align-items-center">
            {translate("cWsBilling.vat", false, { tax: "VAT" })} (<FormatNumber number={data.vatRate} formatStr={"0.00%"} />)
          </div>
          <div className="col-3 p-2 d-flex align-items-center text-left">
            {data.invoiceCurrency} <FormatNumber number={data.invoiceAmountExcVat * data.vatRate} formatStr={"0.00"} />
          </div>
        </div>
        <div className="row mx-0 bg-primary text-white text-bold">
          <div className="col-9 p-2 d-flex align-items-center">{translate("cWsBilling.totalInvoicedAmount", false, null)}</div>
          <div className="col-3 p-2 d-flex align-items-center text-left">
            {data.invoiceCurrency} <FormatNumber number={data.invoiceAmountExcVat * (1 + data.vatRate)} formatStr={"0.00"} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <p className="text-italic m-0 mt-3">{translate("cWsBilling.noActiveSubs", false, null)}</p>
  );
};

Invoice.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Invoice);
