export const pDomainsAllTlds = {
  allTlds: {
    en: "All available domain TLDs and pricing",
    nl: "Alle beschikbare domeinnaam extensies en prijzen",
  },
  serverError: {
    en: "A server error occured while retrieving most recent domain extension information. Please reload this page.",
    nl: "Er is een serverfout opgetreden tijdens het ophalen van de meest recente domeinnaam informatie. Probeer het aub opnieuw.",
  },
  all: {
    en: "All",
    nl: "Alle",
  },
  register: {
    en: "Register",
    nl: "Registeren",
  },
  transfer: {
    en: "Transfer",
    nl: "Verhuizen",
  },
  renewal: {
    en: "Renewal",
    nl: "Vernieuwen",
  },
  restore: {
    en: "Restore",
    nl: "Herstellen",
  },
  showingDomains: {
    en: "Showing domains",
    nl: "Domeinnamen",
  },
  of: {
    en: "of",
    nl: "van",
  },
};
