export const cCartSubscription = {
  subscription: {
    en: "Product",
    nl: "Product",
  },
  price: {
    en: "Price",
    nl: "Prijs",
  },
  billing: {
    en: "Billing",
    nl: "Facturering",
  },
  upfront: {
    en: "Upfront",
    nl: "Vooraf",
  },
  monthly: {
    en: "Monthly",
    nl: "Maandelijks",
  },
  domain: {
    en: "Domain",
    nl: "Domein",
  },
  changeReflectedNextMonthlyInvoice: {
    en: "Change will be reflected in your next monthly invoice",
    nl: "Verandering zal worden meegenomen in uw volgende maandelijkse invoice",
  },
};
