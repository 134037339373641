export const cWsBilling = {
  invoice: {
    en: "Invoice",
    nl: "Factuur",
  },
  from: {
    en: "From",
    nl: "Van",
  },
  netherlands: {
    en: "the Netherlands",
    nl: "Nederland",
  },
  vatNumberSatonda: {
    en: "VAT",
    nl: "BTW",
  },
  invoiceDetails: {
    en: "Invoice details",
    nl: "Factuur details",
  },
  invoiceRef: {
    en: "Invoice reference",
    nl: "Factuur referentie",
  },
  invoiceDate: {
    en: "Invoice date",
    nl: "Factuur datum",
  },
  paymentDueDate: {
    en: "Payment due date",
    nl: "Betaaldatum",
  },
  notIssuedYet: {
    en: "Not issued yet",
    nl: "Nog niet uitgegeven",
  },
  invoiceAmount: {
    en: "Invoice amount",
    nl: "Factuur bedrag",
  },
  status: {
    en: "Status",
    nl: "Status",
  },
  paid: {
    en: "Paid",
    nl: "Betaald",
  },
  unpaid: {
    en: "Unpaid",
    nl: "Onbetaald",
  },
  billingDetails: {
    en: "Billing details",
    nl: "Facturatie gegevens",
  },
  item: {
    en: "Product",
    nl: "Product",
  },
  period: {
    en: "Period",
    nl: "Periode",
  },
  totalExcVat: {
    en: "Total excluding %tax%",
    nl: "Totaal exclusief BTW",
  },
  vat: {
    en: "%tax%",
    nl: "BTW",
  },
  totalInvoicedAmount: {
    en: "Total invoiced amount",
    nl: "Totaal bedrag",
  },
  noActiveSubs: {
    en: "You don't have any active monthly subcriptions this month.",
    nl: "U heeft geen actieve maandelijkse abonnementen deze maand.",
  },
  generatingPdf: {
    en: "Generating pdf..",
    nl: "PDF genereren..",
  },
  selectInvoice: {
    en: "Select invoice",
    nl: "Selecteer factuur",
  },
  currentMonthToDate: {
    en: "Current month to date",
    nl: "Huidige maand tot nu toe",
  },
  downloadInvoice: {
    en: "Download invoice",
    nl: "Download factuur",
  },
  noInvoiceSelected: {
    en: "No invoice selected",
    nl: "Geen invoice geselecteerd",
  },
  loadingPDF: {
    en: "Loading PDF..",
    nl: "PDF laden..",
  },
  accruedInvoiceMTD: {
    en: "Accrued invoice for current month to date",
    nl: "Factuur voor huidige maand tot nu toe",
  },
  invoiceFor: {
    en: "Invoice for",
    nl: "Factuur voor",
  },
  genCredit: {
    en: "You have a general credit of",
    nl: "U heeft een algemeen krediet van",
  },
  refCredit: {
    en: "and a referral credit of",
    nl: "en een referral krediet van",
  },
  willBeCredited: {
    en: "These amounts will be credited to your invoice this month, and only the remainder will be billed.",
    nl: "Deze bedragen zullen van uw factuur worden afgetroken en alleen het overblijvende bedrag zal worden gefactureerd.",
  },
  overdueInvoices: {
    en: "Overdue invoices",
    nl: "Achterstallige facturen",
  },
  amountDue: {
    en: "Amount due (inc %tax%)",
    nl: "Te betalen bedrag (inc BTW)",
  },
  pay: {
    en: "Pay",
    nl: "Betaal",
  },
  // NOTE:
  // - Product names in English should match those in ProductVars' PRODUCT_NAMES var
  // - Key names should be the English product name concatenated and in lower case
  product_hosting: {
    en: "Hosting",
    nl: "Hosting",
  },
  product_domainregistration: {
    en: "Domain registration",
    nl: "Domein registratie",
  },
  product_sitebuilder: {
    en: "Sitebuilder",
    nl: "Sitebuilder",
  },
  product_emailmarketing: {
    en: "Email marketing",
    nl: "Email marketing",
  },
  product_domaintransfer: {
    en: "Domain transfer",
    nl: "Domein verhuizing",
  },
};
