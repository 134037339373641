export const PERIOD_ANNUALLY = "annually";
export const PERIOD_MONTHLY = "monthly";

export const PRODUCT_NAMES = {
  hosting: "Hosting",
  domains: "Domain registration",
  sitebuilder: "Sitebuilder",
  emailMarketing: "Email marketing",
  domainTransfer: "Domain transfer",
};

export const PRODUCT_NAME_CREDIT = "Credit";
export const PACKAGE_NAME_CREDIT_REFERRAL = "Referral credit";
export const PACKAGE_NAME_CREDIT_GENERAL = "General credit";

export const PACKAGE_NAMES = ["Starter", "Advanced", "Pro", "Expert"];
export const PACKAGE_NAMES_HOSTING = ["Starter", "Advanced", "Pro", "Expert", "Elite"];
export const PACKAGE_NAMES_SITEBUILDER = ["Starter", "Advanced", "Expert"];
export const PACKAGE_NAMES_EMAIL_MARKETING = ["Starter", "Advanced", "Pro", "Expert"];
