export const pDomainsManageDomains = {
  registeredUntil: {
    en: "Domain registered until",
    nl: "Domein geregistreerd tot",
  },
  automaticRenewal: {
    en: "Will be automatically renewed.",
    nl: "Wordt automatisch verlengd.",
  },
  cancelAtEndMonth: {
    en: "Cancel at end of month",
    nl: "Annuleer aan het einde van de maand",
  },
  setToBeCancelledMonth: {
    en: "Order the subscription to be cancelled at the end of this month",
    nl: "Geef door dat het abonnement geannuleerd moet worden per het einde van deze maand",
  },
  toBeCancelled: {
    en: "To be cancelled",
    nl: "Wordt geannuleerd",
  },
  unCancel: {
    en: "Reverse order to cancel",
    nl: "Geplande annulering terugdraaien",
  },
  cancelAtEndPeriod: {
    en: "Cancel at end of subscription period",
    nl: "Annuleer aan het einde van de abonnementsperiode",
  },
  setToBeCancelledPeriod: {
    en: "Order the subscription to be cancelled at the end of its subscription period",
    nl: "Geef door dat het abonnement geannuleerd moet worden per het einde van haar abonnementsperiode",
  },
  restoreDomain: {
    en: "Restore domain",
    nl: "Domein herstellen",
  },
  restoreYourDomain: {
    en: "Restore your domain",
    nl: "Herstel uw domeinnaam",
  },
  inCart: {
    en: "In shopping cart",
    nl: "In winkelwagen",
  },
  checkoutCartToRestore: {
    en: "Check out your shopping cart to restore your domain",
    nl: "Reken winkelwagen af om domein te herstellen",
  },
  initTransfer: {
    en: "Initiate transfer of domain to another registrar",
    nl: "Start domein overdracht van andere registrar",
  },
  transferDomain: {
    en: "Transfer domain",
    nl: "Domein verhuizen",
  },
  updateOwnership: {
    en: "Update ownership details",
    nl: "Eigendomsinformatie bijwerken",
  },
  manageRenewal: {
    en: "Manage renewal",
    nl: "Beheer verlenging",
  },
  manageDns: {
    en: "Manage DNS",
    nl: "DNS beheren",
  },
  duringTransfer: {
    en: "During the transfer process, important information will be sent to email address %email%. Please confirm you have access to this email address.",
    nl: "Tijdens het overdrachtproces zal belangrijke informatie worden gestuurd naar emailadres %email%. Wees er zeker aub van dat u toegang heeft tot dit email adres.",
  },
  manageDomains: {
    en: "Manage your domains",
    nl: "Beheer uw domeinnamen",
  },
  noDomainsManaged: {
    en: "You don't have any domains managed through our platform.",
    nl: "U beheert nog geen domeinnamen via ons platform.",
  },
  domainName: {
    en: "Domain name",
    nl: "Domeinnaam",
  },
  billingDetails: {
    en: "Billing details",
    nl: "Factureringsinformatie",
  },
  actions: {
    en: "Actions",
    nl: "Acties",
  },
};
