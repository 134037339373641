import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FormatNumber from "../../layout/FormatNumber";
import Invoice from "./Invoice";
import DownloadInvoicePDF from "./DownloadInvoicePDF";

import { translate } from "../../../translations/translations";
import { countries } from "../../../lib/Countries";
import { getCurrency } from "../../../lib/Currencies";
import { getVatRate, isWsVatNumberConfirmed } from "../../../lib/VatRates";
import { capitalizeFirstLetter, formatDate } from "../../../lib/formattingFunctions";

const ShowInvoice = ({ auth: { user, activeWorkspace } }) => {
  const [activeSubsCurrMonth, setActiveSubsCurrMonth] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    if (activeWorkspace !== null) {
      let startOfCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      setActiveSubsCurrMonth(
        activeWorkspace.subscriptions.filter((sub) => typeof sub.cancelDate === "undefined" || new Date(sub.cancelDate) >= startOfCurrentMonth)
      );
    } else {
      setActiveSubsCurrMonth([]);
    }
    // eslint-disable-next-line
  }, [activeWorkspace]);

  const selectInvoice = (invoiceId) => {
    if (invoiceId === "") {
      setSelectedInvoice(null);
    } else {
      setSelectedInvoice(activeWorkspace.invoices.filter((invoice) => invoice._id === invoiceId)[0]);
    }
  };

  const sortByDate = (a, b) => {
    if (a.invoiceDate > b.invoiceDate) {
      return -1;
    }
    if (a.invoiceDate < b.invoiceDate) {
      return 1;
    }
    return 0;
  };

  const workspaceHasPositiveCredit = () => {
    return activeWorkspace.generalCredit > 0 || activeWorkspace.referralCredit > 0;
  };

  const transformCurrSubsToInvoiceFormat = (subscriptions) => {
    // Transform subscription data to same data format as invoices have: { productName, packageName, fromDate, toDate, invoiceAmount }
    // This is the similar code as in "getInvoiceItemMonthlySub" function on backend, except here you look at current month while backend looks at last month
    return subscriptions
      .filter((sub) => !sub.prepaid)
      .map((sub) => {
        const { productName, packageName, startDate, renewDate, cancelDate, monthlyPrice } = sub;
        let firstDayCurrMonth = getFirstDayCurrMonth();
        let lastDayCurrMonth = getLastDayCurrMonth();
        let fromDate = getInvoiceFromDate(new Date(startDate));
        let toDate = getInvoiceToDate(typeof cancelDate === "undefined" ? new Date(renewDate) : new Date(cancelDate));
        let timeFraction = (toDate - fromDate) / (lastDayCurrMonth - firstDayCurrMonth);
        return {
          productName,
          packageName,
          fromDate,
          toDate,
          invoiceAmount: monthlyPrice * timeFraction,
        };
      });
  };

  const getFirstDayCurrMonth = () => {
    let today = Date.now();
    return new Date(new Date(today).getFullYear(), new Date(today).getMonth(), 1);
  };

  const getLastDayCurrMonth = () => {
    let today = Date.now();
    return new Date(new Date(today).getFullYear(), new Date(today).getMonth() + 1, 0);
  };

  const getInvoiceFromDate = (startDate) => {
    let firstDayCurrMonth = getFirstDayCurrMonth();
    return startDate > firstDayCurrMonth ? startDate : firstDayCurrMonth;
  };

  const getInvoiceToDate = (endDate) => {
    let today = new Date();
    return endDate < today ? endDate : today;
  };

  // Get all data needed to render the invoices (both in html as well as in pdf)
  const currMonth = selectedInvoice === null;
  const countryCode = currMonth ? activeWorkspace.location.countryCode : selectedInvoice?.wsData?.location.countryCode || "";
  const invoiceItems = selectedInvoice === null ? activeSubsCurrMonth : selectedInvoice.items;
  const invoiceItemsToRender = currMonth ? transformCurrSubsToInvoiceFormat(invoiceItems) : invoiceItems;
  const invoiceHeader =
    selectedInvoice === null
      ? translate("cWsBilling.accruedInvoiceMTD", false, null)
      : `${translate("cWsBilling.invoiceFor", false, null)} ${formatDate(selectedInvoice.invoiceDate, "en-NL", "d mmmm yyyy")}`;
  const invoiceData = {
    invoiceId: currMonth ? "n.a." : selectedInvoice._id,
    invoiceDate: currMonth ? "n.a." : selectedInvoice.invoiceDate,
    currMonth,
    invoiceHeader,
    from: {
      from: translate("cWsBilling.from", false, null),
      satondaName: "Satonda",
      satondaStreet: "Pampuslaan 180",
      satondaPostalCityCountry: `1087LA, Amsterdam, ${translate("cWsBilling.netherlands", false, null)}`,
      satondaVatNumber: `${translate("cWsBilling.vatNumberSatonda", false, null)}: NL004963541B02`,
    },
    invoiceDetails: {
      invoiceDetails: translate("cWsBilling.invoiceDetails", false, null),
      invoiceRef: `${translate("cWsBilling.invoiceRef", false, null)}: ${currMonth ? "n.a." : selectedInvoice._id}`,
      invoiceDate: `${translate("cWsBilling.invoiceDate", false, null)}: ${
        currMonth ? translate("cWsBilling.notIssuedYet", false, null) : formatDate(selectedInvoice.invoiceDate, user.language, "dmmyy")
      }`,
      dueDate: `${translate("cWsBilling.paymentDueDate", false, null)}: ${
        currMonth ? translate("cWsBilling.notIssuedYet", false, null) : formatDate(selectedInvoice.invoiceDate, user.language, "dmmyy")
      }`,
      // paymentStatus: `${translate("cWsBilling.status", false, null)}: ${
      //   currMonth ? translate("cWsBilling.notIssuedYet", false, null) : capitalizeFirstLetter(selectedInvoice.paymentStatus)
      // }`,
      paymentStatus: `${translate("cWsBilling.status", false, null)}: ${
        currMonth ? translate("cWsBilling.notIssuedYet", false, null) : translate(`cWsBilling.${selectedInvoice.paymentStatus}`, false, null)
      }`,
    },
    billingDetails: {
      billingDetails: translate("cWsBilling.billingDetails", false, null),
      wsId: currMonth ? activeWorkspace._id : selectedInvoice.wsId,
      wsName: capitalizeFirstLetter(currMonth ? activeWorkspace.name : selectedInvoice.wsData?.name || ""),
      customerName: capitalizeFirstLetter(currMonth ? activeWorkspace.companyName : selectedInvoice.wsData?.companyName || ""),
      email: currMonth ? activeWorkspace.email : selectedInvoice.wsData?.email || "",
      street: capitalizeFirstLetter(currMonth ? activeWorkspace.location.street : selectedInvoice.wsData?.location.street || ""),
      postal: capitalizeFirstLetter(currMonth ? activeWorkspace.location.postal : selectedInvoice.wsData?.location.postal || ""),
      city: capitalizeFirstLetter(currMonth ? activeWorkspace.location.city : selectedInvoice.wsData?.location.city || ""),
      country: countries.filter((country) => country.code === countryCode)[0]?.localName || "",
    },
    invoiceItemsToRender: invoiceItemsToRender,
    invoiceCurrency: currMonth ? getCurrency(activeWorkspace.location.priceCountry).currencyCode : selectedInvoice.currency,
    invoiceAmountExcVat: invoiceItemsToRender.map((item) => item.invoiceAmount).reduce((a, b) => a + b, 0),
    vatRate: currMonth
      ? getVatRate(activeWorkspace.location.countryCode, activeWorkspace.customerType, isWsVatNumberConfirmed(activeWorkspace))
      : parseFloat(selectedInvoice.rateVAT),
    language: user.language,
  };

  return (
    <>
      <div className="d-flex align-items-center mt-5 mb-0">
        <p className="text-italic my-0 me-3">{translate("cWsBilling.selectInvoice", false, null)}</p>
        <select
          className="form-select form-select-sm m-0 w-25"
          name="selectedInvoice"
          value={selectedInvoice === null ? "" : selectedInvoice._id}
          onChange={(e) => selectInvoice(e.target.value)}
        >
          <option value="">{translate("cWsBilling.currentMonthToDate", false, null)}</option>
          {typeof activeWorkspace.invoices !== "undefined" &&
            activeWorkspace.invoices.length > 0 &&
            activeWorkspace.invoices.sort(sortByDate).map((invoice) => (
              <option key={invoice._id} value={invoice._id}>
                {formatDate(invoice.invoiceDate, "en-NL", "d mmmm yyyy")}
              </option>
            ))}
        </select>
        <DownloadInvoicePDF data={invoiceData} />
      </div>
      <h2 className="text-secondary mt-4 mb-0">{invoiceHeader}</h2>
      {selectedInvoice === null && workspaceHasPositiveCredit() && (
        <>
          <p className="mt-3 mb-2">
            {translate("cWsBilling.genCredit", false, null)} {getCurrency(activeWorkspace.location.priceCountry).currencySign}
            <FormatNumber number={activeWorkspace.generalCredit} formatStr={"0.00"} /> {translate("cWsBilling.refCredit", false, null)}{" "}
            {getCurrency(activeWorkspace.location.priceCountry).currencySign}
            <FormatNumber number={activeWorkspace.referralCredit} formatStr={"0.00"} />.
          </p>
          <p className="mb-0">{translate("cWsBilling.willBeCredited", false, null)}</p>
        </>
      )}
      <div>
        <Invoice data={invoiceData} />
      </div>
    </>
  );
};

ShowInvoice.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(ShowInvoice);
