export const pCart = {
  shoppingCart: {
    en: "Shopping cart",
    nl: "Winkelwagentje",
  },
  noItemsInCart: {
    en: "You don't have any items in your cart yet.",
    nl: "Je winkelwagentje is nog leeg.",
  },
  totalExcVat: {
    en: "Total excluding VAT",
    nl: "Totaal exclusief BTW",
  },
  vat: {
    en: "VAT",
    nl: "BTW",
  },
  totalPayable: {
    en: "Total payable at checkout",
    nl: "Totaal af te rekenen",
  },
  proceedCheckout: {
    en: "Proceed to checkout",
    nl: "Ga door naar afrekenen",
  },
  addSubsToAccount: {
    en: "Add #product|products# to your account!",
    nl: "Voeg #het product|de producten# toe aan je account!",
  },
};
