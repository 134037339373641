import axios from "axios";

import { SET_SESSION_CART, LOAD_CART, ADD_TO_CART, REMOVE_FROM_CART, EMPTY_CART, CART_ERROR } from "./types";

import { setGlobalLoading, removeGlobalLoading } from "./globalLoading";
import { loadUser } from "./auth";
import { setAlert } from "./alert";

import { POST_CONFIG, API_URL } from "../lib/GeneralVars";
import { translate } from "../translations/translations";

export const setCartSession = (sessionId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aCart.loadingCart", false, null)));
  try {
    dispatch({
      type: SET_SESSION_CART,
      payload: sessionId,
    });
  } catch (error) {
    dispatch({ type: CART_ERROR });
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const loadCart = (sessionId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aCart.loadingCart", false, null)));
  try {
    // Get full cart from backend
    const res = await axios.get(`${API_URL}/cart/pvt/get/${sessionId}`);
    dispatch({
      type: LOAD_CART,
      payload: res.data,
    });
  } catch (error) {
    dispatch({ type: CART_ERROR });
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const addToCart = (sessionId, productPackage) => async (dispatch) => {
  // Don't show loading indicator, so that globalLoading spinner doesn't show and DOM remains as is
  // let loadingId = dispatch(setGlobalLoading(translate("aCart.loadingCart", false, null)));
  try {
    // Add new item to cart and retrieve full cart from backend
    const body = JSON.stringify({ sessionId, productPackage });
    const res = await axios.post(`${API_URL}/cart/pvt/add/newSub`, body, POST_CONFIG);
    dispatch({
      type: ADD_TO_CART,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert(translate("aCart.errorAddingToCart", false, null), "danger"));
    dispatch({ type: CART_ERROR });
  }
  // dispatch(removeGlobalLoading(loadingId));
};

export const requestDomainTransfer = (subId, sessionId) => async (dispatch) => {
  // Don't show loading indicator, so that globalLoading spinner doesn't show and DOM remains as is
  // let loadingId = dispatch(setGlobalLoading(translate("aCart.loadingCart", false, null)));
  try {
    // Add new item to cart and retrieve full cart from backend
    const body = JSON.stringify({ subId, sessionId });
    const res = await axios.post(`${API_URL}/domains/requestTransfer`, body, POST_CONFIG);
    dispatch({
      type: ADD_TO_CART,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert(translate("aCart.errorAddingToCart", false, null), "danger"));
    dispatch({ type: CART_ERROR });
  }
  // dispatch(removeGlobalLoading(loadingId));
};

export const removeFromCart = (sessionId, itemId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aCart.loadingCart", false, null)));
  try {
    // Remove item from cart and retrieve full cart from backend
    const body = JSON.stringify({ sessionId, itemId });
    const res = await axios.post(`${API_URL}/cart/pvt/remove`, body, POST_CONFIG);
    dispatch({
      type: REMOVE_FROM_CART,
      payload: res.data,
    });
  } catch (error) {
    dispatch({ type: CART_ERROR });
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const checkout = (sessionId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aCart.addingSub", false, null)));
  try {
    // Remove item from cart and retrieve full cart from backend
    const body = JSON.stringify({ sessionId });
    await axios.post(`${API_URL}/cart/pvt/checkout`, body, POST_CONFIG);
    dispatch({
      type: EMPTY_CART,
    });
    dispatch(removeGlobalLoading(loadingId));
    await dispatch(loadUser());
    return true;
  } catch (error) {
    dispatch({ type: CART_ERROR });
    dispatch(removeGlobalLoading(loadingId));
    return false;
  }
};

export const emptyShoppingCart = () => async (dispatch) => {
  dispatch({
    type: EMPTY_CART,
  });
};
